@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: #fff;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 124%;
  letter-spacing: -0.019em;
  margin-bottom: 14px;
}

p {
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 124%;
  letter-spacing: -0.019em;
}

h2,
label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 124%;
  letter-spacing: -0.019em;
  @apply block mb-2;
}

.fixed-col {
  @apply absolute w-screen;
  bottom: -20px;
}

.card {
  @apply flex flex-col text-center bg-gray-700 rounded-md py-10 px-20;
}

.stat {
  @apply mb-0;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
}

form {
  width: 100%;
}

select {
  @apply bg-gray-500 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

select option {
  @apply text-black;
}

.range {
  accent-color: #e52044;
}

.small {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 124%;
  letter-spacing: -0.019em;
}

.site-navigation {
  padding: 1rem 2rem;
  min-height: 160px;
  background-color: #002a42;
}

.site-navigation .container {
  position: relative;
}

.site-navigation__top-links {
  display: flex;
  justify-content: flex-end;
}

.site-navigation__top-links a {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.site-navigation__top-links a svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.site-navigation__top-links a:first-of-type {
  position: relative;
}

.site-navigation__top-links a:hover {
  color: #b17312;
}

.site-navigation__nav-primary-item a.active {
  border-color: #b17312;
}

.site-navigation__inner {
  position: relative;
}

@media (min-width: 769px) {
  .site-navigation__inner {
    display: flex;
    flex-wrap: wrap;
  }
}

.site-navigation__logo a {
  display: block;
  width: 320px;
}

.site-navigation__logo a svg {
  width: 320px;
}

@media (min-width: 769px) {
  .site-navigation__logo {
    flex: 0 0 calc(16.66667% - 40px);
    margin: 0 20px;
  }

  .site-navigation__logo a {
    width: 172px;
  }

  .site-navigation__logo a svg {
    height: 104px;
  }
}

@media (min-width: 1025px) {
  .site-navigation__logo {
    flex: 0 0 calc(25% - 40px);
    margin: 0 0 10px 0;
  }
}

.site-navigation__nav-primary {
  flex-grow: 1;
  margin-top: 5px;
  border-top: 1px solid #f7f7f7;
}

.site-navigation__nav-primary ul {
  margin: 0;
  padding: 0;
}

.site-navigation__nav-primary-item {
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  font-family: "Inter", sans-serif;
  line-height: 1;
}

.site-navigation__nav-primary-item:first-child a {
  margin-left: 0;
}

.site-navigation__nav-primary-item a {
  display: block;
  margin: 0 18px;
  padding: 20px 0 10px;
  margin-bottom: 1rem;
  height: 100%;
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease-in;
}

@media (min-width: 768px) {
  .site-navigation__nav-primary-item a {
    padding: 32px 0 10px;
    margin-bottom: 0rem;
  }
}

.site-navigation__nav-primary-item a.is-active,
.site-navigation__nav-primary-item a:hover {
  border-bottom-color: #b17312;
}

.site-navigation__nav-overlay {
  display: none;
}

.site-navigation--transparent {
  background: linear-gradient(
    180deg,
    rgba(0, 42, 66, 0.8),
    rgba(0, 42, 66, 0.05) 86.7%,
    rgba(0, 42, 66, 0)
  );
}

.toggle-nav {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 10px;
  height: 40px;
  width: 40px;
  text-align: center;
}

.toggle-nav__inner {
  position: relative;
  height: 18px;
  width: 20px;
}

@media (min-width: 769px) {
  .toggle-nav {
    top: 40px;
    right: 12px;
  }
}

.toggle-nav:focus {
  outline: none;
}

.toggle-nav .bar {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background: #fff;
  transform-origin: center center;
  transition: all 0.2s;
}

.toggle-nav .bar:first-child {
  top: 0;
}

.toggle-nav .bar:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.toggle-nav .bar:nth-child(3) {
  bottom: 0;
}

.toggle-nav.is-open {
  top: -10px;
  right: -6px;
  z-index: 501;
  padding: 5px;
  transform: scale(1.2);
}

.toggle-nav.is-open .toggle-nav__inner {
  height: 28px;
  width: 28px;
}

@media (min-width: 769px) {
  .toggle-nav.is-open {
    top: 40px;
    right: 5px;
  }
}

@media (min-width: 1025px) {
  .toggle-nav.is-open {
    right: 12px;
  }
}

.toggle-nav.is-open .bar:first-child {
  top: 50%;
  transform: rotate(45deg);
}

.toggle-nav.is-open .bar:nth-child(2) {
  opacity: 0;
}

.toggle-nav.is-open .bar:nth-child(3) {
  top: 50%;
  bottom: auto;
  transform: rotate(-45deg);
}

.overlay-nav {
  position: fixed;
  top: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  color: #fff;
  background: #002a42;
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.1s ease-in-out;
}

.overlay-nav__items {
  position: relative;
  padding-top: 130px;
  padding-bottom: 32px;
}

.overlay-nav__items ol {
  margin: 0;
  padding: 0;
  counter-reset: list-counter;
  list-style: none;
}

.overlay-nav__items ol > li {
  counter-increment: list-counter;
}

.overlay-nav__items ol > li:before {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.66667;
  font-family: Timmons, sans-serif;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  content: "0" counter(list-counter);
  color: #809eb4;
}

@media (max-width: 768px) {
  .overlay-nav__items ol > li:before {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .overlay-nav__items {
    min-height: 70vh;
    background: #003a5c;
  }

  .overlay-nav__items ol {
    display: flex;
    flex-wrap: wrap;
    padding-right: 8.5%;
    padding-left: 8.5%;
  }

  .overlay-nav__items ol > li {
    width: 37.5%;
  }

  .overlay-nav__items ol > li:nth-child(2n) {
    margin-left: 14.5%;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__items {
    margin-bottom: 0;
    padding-top: 156px;
    height: 100%;
    background: #003a5c;
  }

  .overlay-nav__items ol {
    display: flex;
    flex-wrap: wrap;
  }

  .overlay-nav__items ol > li {
    margin-right: 3%;
    width: 31.2%;
  }

  .overlay-nav__items ol > li:nth-child(3n) {
    margin-right: 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .overlay-nav__items:after {
    right: -32px;
  }

  .overlay-nav__items:after,
  .overlay-nav__items:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 32px;
    background: #003a5c;
  }

  .overlay-nav__items:before {
    left: -32px;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__items:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: #003a5c;
  }
}

.overlay-nav__item > a {
  font-size: 92px;
  font-size: 5.75rem;
  line-height: 0.78261;
  font-family: Timmons, sans-serif;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
}

.overlay-nav__item > a.is-active,
.overlay-nav__item > a:hover {
  color: #ca8a04;
}

@media (min-width: 769px) {
  .overlay-nav__item > a {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.2;
    padding-left: 8px;
  }
}

.overlay-nav__item__children {
  margin: 0 0 42px;
  padding: 0;
  list-style: none;
}

.overlay-nav__item__children li {
  padding: 0 0 0 28px;
}

.overlay-nav__item__children li a {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 2.85714;
  display: block;
  color: #fff;
  border-bottom: 1px solid #406d8f;
}

.overlay-nav__item__children li a:hover {
  color: #ca8a04;
}

@media (max-width: 768px) {
  .overlay-nav__item__children {
    display: none;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__item__children {
    margin-bottom: 92px;
    padding-right: 16%;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__item {
    min-height: 284px;
  }
}

.overlay-nav__aside {
  background: #002a42;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .overlay-nav__aside {
    margin-left: 8.33333% !important;
    width: 83.33333%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .overlay-nav__aside > * {
    width: 48%;
  }

  .overlay-nav__aside > :nth-child(2n) {
    margin-left: 4%;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__aside {
    margin-top: 176px;
  }

  .overlay-nav__aside .link-list {
    min-height: 246px;
  }
}

@media (min-width: 1025px) {
  .overlay-nav .container,
  .overlay-nav .grid {
    height: 100%;
  }

  .overlay-nav .col-lg-3,
  .overlay-nav .col-lg-9 {
    margin-bottom: 0;
  }
}

.overlay-nav__promo {
  margin-right: -32px;
  margin-left: -32px;
}

@media (max-width: 768px) {
  .overlay-nav__promo {
    display: none;
  }
}

@media (min-width: 1025px) {
  .overlay-nav__promo {
    margin: 0 -68px 0 -40px;
  }
}

@media (min-width: 1441px) {
  .overlay-nav__promo {
    margin-right: -23%;
  }
}

.nav-is-showing .overlay-nav {
  z-index: 500;
  opacity: 1;
  transform: scale(1);
}

.habitat--placeholder {
  min-height: 100vh;
  min-width: 100%;
  background-color: transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.floating-hero {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  object-fit: cover;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 32, 55, 0.525784) 40.15%,
    rgba(0, 60, 105, 0.1) 100%
  );
}

.floating-hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0% 0;
  opacity: 0.8;
}

.button {
  background: #ca8a04;
  color: #000;
  padding: 1rem;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.form-item:first-child {
  margin-top: 2rem;
}

.form-item {
  margin-bottom: 2.5rem;
}

.radio {
  opacity: 0;
}

.radio-button-label {
  margin-left: -10px;
  margin-bottom: 0;
  cursor: pointer;
}

@media (min-width: 768px) {
  footer {
    position: absolute;
    bottom: 15px;
    left: 10vw;
    width: 80vw;
  }
}

footer {
  margin: auto;
  border-top: 1px solid #fff;
  width: 80%;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

footer a {
  color: #fff;
  display: flex;
  text-align: center;
}

footer a svg {
  position: relative;
  top: 5px;
  left: 5px;
}

.main-bg {
  background-image: url(../public/RockClimber.jpeg);
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: top right;
  z-index: -1;
}

.mainwrapper {
  position: relative;
}

.mainwrapper::before {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #b41b37;
  mix-blend-mode: multiply;
}

.main-content {
  position: relative;
}

.item-selector {
  @apply bg-white hover:bg-primary-500 w-full border-b border-gray-200 sm:border-b-0 sm:border-r  h-full;
}

.item-selector:hover label > *,
.item-selector:hover label {
  color: #fff !important;
}

/* .item-selector label, .item-selector label span {
  @apply hover:text-white;
} */

.item-selector--selected {
  @apply bg-primary-500 w-full border-b border-gray-200 sm:border-b-0 sm:border-r h-full;
}

.selector li:first-child {
  @apply rounded-t-md lg:rounded-tl-md lg:rounded-tr-none;
}

.selector li:last-child {
  @apply rounded-br-md lg:rounded-tr-md lg:rounded-br-md;
}

.minHeight {
  min-height: 100vh;
}

.multiple-choice-selector {
  @apply flex items-center uppercase text-sm font-bold bg-green-500 text-white py-2 px-3 mr-3 rounded-md;
}

.multiple-choice-selector--selected {
  @apply flex items-center bg-gray-200 font-bold text-gray-400 uppercase text-sm py-2 px-3 mr-3 rounded-md;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.tooltip p {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
